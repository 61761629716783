import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Media from 'react-bootstrap/Media';
import Button from 'react-bootstrap/Button';

class App extends Component {
  render() {
    return (
            <Container className="bg-grey text-dark">
              <Row className="text-center">
                <Col>
                  <Media>
                    <Media.Body className="mb-5 mt-5">                    
                      <h1>
                        <img
                          width={80}
                          height={78}
                          className="align-self-center mr-4"
                          alt="Andika Kurniantoro"
                          src={process.env.PUBLIC_URL + '/dika_circle_opt.png'}
                        />                      
                        Hi,
                      </h1>
                    </Media.Body>
                  </Media>
                </Col>
              </Row>
              <Row>
              <Col sm={8}>
                  <p>I'm Andika (Dika).</p> 
                  <p>If you find any account out there named <a target="_blank" rel="noopener noreferrer" href="https://bakulrujak.com">bakulrujak</a>, they're also probably me.</p>
                  <p>I'm doing code and server operations since 2007. I used to handled physical servers (bunch of it), but not anymore. <a target="_blank" rel="noopener noreferrer" href="https://aibusiness.com/2019-trends-cloud-computing/">Cloud</a> is way more futuristic, cheaper, more reliable, more scalabale, more this and more that. Yeah, then I <a target="_blank" rel="noopener noreferrer" href="https://www.slideshare.net/bakulrujak/cloud-and-the-deatch-of-the-sysadmin">jumped</a> into this shiny thing. Until now. </p>
                  <p>I have a <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/vicky-aprillia-5a590420/">wife</a>, one <a target="_blank" rel="noopener noreferrer" href="https://bakulrujak.com/tentang-waktu-yang-berjalan-lebih-cepat-dari-kesepakatan-30be89e7a50b">kid</a> (oh, he's a boy!), and we're living at Singapore at the moment. </p>
                  <p>Here's my simple rule; if I'm not working, I'm with my family. If I'm not with my family, I'm working. To be honest with you, those words aren't originally mine, I quoted them from someone. I'm not that brilliant, afterall.</p>
                  <p>I love servers (Hint: serverless are <a target="_blank" rel="noopener noreferrer" href="https://www.booleanworld.com/serverless-computing-explained/">using servers!</a>), code, automate the repetitive and boring things, books (one that's written on paper), <a target="_blank" rel="noopener noreferrer" href="https://www.meetup.com/AWS-User-Group-Indonesia/events/255925416/">talking</a>, making friends, and... many more! So let's talk about things over a coffee some day.</p>
                  <p>Ah, right, I co-founded (and am writing for) a cool-popular-science-for-kids website named <a target="_blank" rel="noopener noreferrer" href="https://sains.me">SainsMe</a>. Take a look! </p>
                  <p>Well, that's all. Anything, drop <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/bakulrujak">me</a> messages.</p>
                  <p>Cheers!</p>
                </Col>                
                <Col sm={4}>
                  <p className="font-weight-bold">Keywords:</p>
                  <Button variant="light" className="mr-2 mb-2">Cloud</Button>
                  <Button variant="light" className="mr-2 mb-2">AWS</Button>
                  <Button variant="light" className="mr-2 mb-2">Python</Button>
                  <Button variant="light" className="mr-2 mb-2">Detikcom</Button>
                  <Button variant="light" className="mr-2 mb-2">NodeJS</Button>
                  <Button variant="light" className="mr-2 mb-2">CI/CD</Button>
                  <Button variant="light" className="mr-2 mb-2">DevOps</Button>
                  <Button variant="light" className="mr-2 mb-2">Ansible</Button>
                  <Button variant="light" className="mr-2 mb-2">Kaskus</Button>
                  <Button variant="light" className="mr-2 mb-2">Coffee</Button>
                  <Button variant="light" className="mr-2 mb-2">Books</Button>
                  <Button variant="light" className="mr-2 mb-2">GCP</Button>
                  <Button variant="light" className="mr-2 mb-2">Tokopedia</Button>
                  <Button variant="light" className="mr-2 mb-2">Packer</Button>
                  <Button variant="light" className="mr-2 mb-2">Serverless</Button>
                  <Button variant="light" className="mr-2 mb-2">Asynchronous</Button>
                  <Button variant="light" className="mr-2 mb-2">API</Button>
                  <Button variant="light" className="mr-2 mb-2">Codepipeline</Button>
                  <Button variant="light" className="mr-2 mb-2">Linux</Button>
                  <Button variant="light" className="mr-2 mb-2">Yojee</Button>
                  <Button variant="light" className="mr-2 mb-2">Lambda</Button>
                  <Button variant="light" className="mr-2 mb-2">Docker</Button>
                  <Button variant="light" className="mr-2 mb-2">Kurio</Button>
                  <Button variant="light" className="mr-2 mb-2">SainsMe</Button>
                  <Button variant="light" className="mr-2 mb-2">Read</Button>
                  <Button variant="light" className="mr-2 mb-2">Write</Button>
                  <Button variant="light" className="mr-2 mb-2">Fargate</Button>
                  <Button variant="light" className="mr-2 mb-2">ECS</Button>
                  <p className="font-weight-bold mt-3">Find me:</p>
                  <Button href="https://www.linkedin.com/in/andikakurniantoro" variant="outline-primary" className="mr-2 mb-2">Linkedin</Button>
                  <Button href="https://twitter.com/bakulrujak" variant="outline-info" className="mr-2 mb-2">Twitter</Button>  
                  <Button href="https://github.com/bakulrujak" variant="outline-secondary" className="mr-2 mb-2">Github</Button>
                  <Button href="https://bakulrujak.com" variant="outline-danger" className="mr-2 mb-2">Medium</Button>
                  <p>This page is built and managed with <br /><a target="_blank" rel="noopener noreferrer" href="https://aws-amplify.github.io/">AWS Amplify</a></p>
                </Col>
              </Row>
            </Container>
    );
  }
}

export default App;